import React, { useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause } from 'react-icons/fa'; // Import des icônes
import heroImage from '../assets/images/hero4.jpg';

// Styles pour la section Home
const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  color: #333;
  padding: 0;
  margin: 0;
`;

// Styles pour la section Hero
const HeroSection = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5), 
      rgba(0, 0, 0, 0.5)
    ), 
    url(${heroImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Styles de texte
const Title = styled.h1`
  font-size: 4rem;
  font-weight: 280;
  margin: 0.5rem 0;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    font-size: 3rem;
    padding: 0px 10px;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
    padding: 0px 10px;
  }
`;

const LinkText = styled.p`
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 270;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    padding: 0px 20px;
  }

  @media (max-width: 480px) {
    padding: 0px 20px;
  }
`;

const Link = styled.a`
  color: #56a09c;
  text-decoration: none;

  &:hover {
    color: #1d6154;
  }
`;

// Bouton de lecture de musique
const PlayButton = styled.button`
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  background-color: #56a09c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 200px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
  opacity: 85%;

  &:hover {
    background-color: #1d6154;
  }
`;

// Composant principal
const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <HomeWrapper>
      <HeroSection>
        <Title>Site en construction.</Title>

        <LinkText>
          Visiter mon site actuel en cliquant{' '}
          <Link href="https://www.marcponsbek.com" target="_blank">
            ici.
          </Link>
        </LinkText>

        <PlayButton onClick={handlePlay}>
          {isPlaying ? <FaPause /> : <FaPlay />} {/* Icône conditionnelle */}
          {isPlaying ? 'Pause' : 'Play'}
        </PlayButton>

        <ReactPlayer
          url="hhttps://www.youtube.com/watch?v=iLtGTwIxZZs"
          playing={isPlaying}
          controls={false}
          width="0"
          height="0"
        />
      </HeroSection>
    </HomeWrapper>
  );
};

export default Home;
